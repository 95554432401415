import { config } from "../functions/config";

export default function Strings() {
  return {
    tokenAddress: "TOKEN ADDRESS",
    logoTitle: "Logo",
    aboutTtl: "关于",
    wpTtl: "白皮书",
    allocTtl: "代币分配",
    deckTtl: "演讲稿",
    presaleTtl: "预售",
    rmTtl: "路线图",
    auditTtl: "审计",
    projectTtl: "项目",
    quickLinksTtl: "Quick links",
    privPolicy: "隐私政策",
    termsAndConds: "条款和条件",
    amlPolicy: "反洗钱政策",
    sitemap: "站点地图",
    salePolicy: `${config.tokenSymbol}销售条款和条件`,
    descText1:
      "在这个身临其境的移动动作RPG游戏中，将王国从无政府状态中拯救出来! 使用神奇的法术和凶猛的铁制武器，与来自古代地下世界的敌人作战 关注我们的社交网站，保持更新!",
    descText2:
      "Copyright © 2022-2024 Hitbox Holdings SVG LLC, a St Vincent Corporation",
    descText3:
      "Suite 305 Griffith Corporate Centre, Kingstown, St Vincent, and the Grenadines.",
    quickLinks: "Quick links",
    // presaleStage: 'Presale stage',
    presaleStage: "最后一轮预售！",
    presaleEnded: "预售结束！",
    stayTuned: "请保持关注以获取代币认领指南。",
    thankyouForParticipating: "感谢您参与 SWDTKN 预售！",
    // buyBeforeTheEnd: "Hurry before the end of presale!",
    buyBeforeTheEnd: "Please wait for announcements",
    buyBefore: "请继续关注 IDO 公告。 发售价格：",
    soldTtl: "Sold",
    raisedTtl: "Raised",
    connectWallet: "Connect Wallet",
    minimumPurchWarn: `Purchase amount must be not less than {VALUE1} ${config.tokenSymbol} and no more than {VALUE2} ${config.tokenSymbol}.`,
    minimumPurchWarn2: `Purchase amount must be no more than {VALUE1} ${config.tokenSymbol}.`,
    presaleInactive: "Presale is inactive.",
    insufFunds: "You have not enough money to make a transaction.",
    impossToTx: "It is impossible to make a transaction.",
    invalidTime: "Invalid time.",
    stayAndWait:
      "Please stay on the website and wait for the transaction confirmation",
    goesWrong: "You canceled the operation or something goes wrong.",
    thankYou: "Thank you for your purchase!",
    tokPayFailed: "Token payment failed",
    claimNoFunds: "You have no tokens available for withdrawal.",
    statClaimed: `Your ${config.tokenSymbol} tokens will be sent to your address soon.`,
    connectNote:
      "*Please note that, this action can not be undone once the wallet is authorize.",
    connectTheWallet: "Connect the wallet",
    youOwn: "You own",
    buyWith: "Buy with",
    claimTokens: "Claim",
    buyTokenWith: `Buy ${config.tokenSymbol} with`,
    minimumTtl: "Minimum",
    buyDesc: `Enter the amount of ${config.tokenSymbol} you wish to purchase, then click "Buy"`,
    buyTtl: "Approve",
    payTtl: "Pay",
    benefit1: "独家访问 MVP 封闭测试版",
    benefit2: "访问开发日记",
    benefit3: "限量传奇\n游戏武器",
    benefit4: "自动获得下一次 NFT 销售的白名单资格",
    benefit5: "参加现实和虚拟结合的赠品活动",
    benefit6: "还有更多福利！",
    whybuyToken1: "MVP可玩，beta版将于5月上线",
    whybuyToken2: "Android和iOS上合计下载量达到400万",
    whybuyToken3: "MVP版本创造了600万的收入",
    whybuyToken4: "为游戏开发扩展和用户获取制定了为期两年的全面计划",
    whybuyToken5: "团队由游戏开发资深人员组成",
    approveMessage: `Payment with stablecoins will require confirmation of two transactions: first to approve spending and second to ${config.tokenSymbol} purchase. Stay on the website until the all transactions are confirmed. Only ERC-20 tokens supported.`,
    wygTitle1: "一个动态的砍杀式RPG游戏",
    wygTitle2: "高度身临其境的精美渲染的游戏玩法",
    wygTitle3: "PvE和PvP模式",
    wygTitle4: "250小时以上的战役游戏时间",
    wygTitle5: "提供多种异步游戏模式",
    wygTitle6: "Web3 MVP 将于2024年9月发布",
    wygTitle7: "项目由游戏开发资深人员运营",
    wygTitle8: "支持移动设备和PC的跨平台游戏",
    wygTitle9: "高度沉浸式的16人多人PvP地图",
    wygTitle10: "享受60fps的4k游戏体验（PC）",
    modeT1: "狩猎怪物",
    modeT2: "首领要塞",
    modeT3: "PvP",
    modeT4: "每日地下城",
    modeT5: "活动地下城",
    modeT6: "战役",
    modeInfo:
      "Swords of Blood的MVP提供多种游戏模式！参加当前活动即可获得对封闭Beta的独家访问权（将于5月启动）",
    aboutDesc1: `炫酷的战斗、出色的画面和各种游戏模式，${config.appName} 是第一个在 Ape Chain 区块链上推出的 AAA 级高质量快节奏免费畅玩砍杀 RPG 游戏。`,
    aboutDesc2:
      "暴力、背叛和狡猾的邪恶带来了埃祖拉世界的残酷堕落。背叛者，一个无比邪恶的存在，已经消灭了这个王国坚定的保护者，1000年以来，黑暗一直在统治。",
    aboutDesc3:
      "这是一个绝望的时代，绝望和死亡，因为背叛者的军队覆盖了整个土地。",
    aboutDesc4:
      "但从废墟中，可能的拯救出现了；曾经强大的武士团的最后一个人已经被一位被称为哨兵的古老女神复活了。你们将共同尝试为这片荒凉的土地带来希望的火花。",
    aboutDesc5:
      "成为一个无畏的地下城爬行者，一个赏金猎人的战斗法师，或一个强大的战士，磨练战利品和稀有装备。制作史诗般的装备，让自然界的魔法服从你的意志，并在强大和复仇的史诗般的探索中为你死去的兄弟们报仇。",
    contactUsDesc: `成为《血剑》大使，赚取游戏代币、黄金和宝石。`,
    contactUs: "Contact Us",
    asSeenOnTtl: "见于",
    facTtl1: "探索美丽的埃祖拉世界",
    facTtl2: "掌握各种各样的武器和元素",
    facTtl3: "建立破坏性的攻击组合",
    facTtl4: "制作独特、强大的装备和武器",
    facTtl5: "使用砍杀游戏来杀伤你的对手",
    facTtl6: "在快节奏、残酷的PvP中与其他玩家展开竞争",
    teamBio1:
      "A 36-year veteran of the video game industry who has worked on game titles, such as The Sims, Sims City, Ghost Recon, Rainbow Six, and more), on many levels - from programming to CEO.",
    teamBio2:
      "Worked extensively on the Sony PlayStation and Xbox marketing accounts as well as some of the most iconic game titles including Halo, Call of Duty, Assassin’s Creed and the Tom Clancy franchises.",
    teamBio3:
      "Over 10 years of experience as a gaming executive focused on video games publishing and sales of PC/consoles and mobile Free-To-Play and blockchain-enabled games. Cofounder of Artifex Mundi S.A.",
    teamBio4:
      "Over ten years of experience in community building, marketing, public and client relations, content writing andcreation, and social media management including projects in web3, gamefi, and defi.",
    teamBio5: "A decade of experience in UI/UX design",
    teamBio6:
      "Former Director of Narrative Design for Cyberpunk and The Witcher 3",
    teamBio7:
      "Star Judge and world champion bladesmith on the History Channel’s hit show, Forged in Fire",
    teamBio8:
      "Lead writer for the upcoming megahit: Gord Author and Hollywood Screenplay Writer",
    teamName1: "James Seaman",
    teamTtl1: "CEO",
    teamName2: "Jeremy Brown",
    teamTtl2: "COO",
    teamName3: "Mariusz Szynalik",
    teamTtl3: "Project Director for HitBox Games",
    teamName4: "Yupeng Qin",
    teamTtl4: "Project Director for Asian Dev Team",
    teamName5: "Jakub Szamalek",
    teamTtl5: "Narrative Director",
    teamName6: "John Moyer",
    teamTtl6: "Bass Guitarist of the band Disturbed",
    teamName7: "Vee Lozano",
    teamTtl7: "Business Development Lead",
    teamName8: "Jason Hung",
    teamTtl8: "Advisor",
    teamName9: "Charlie Hu",
    teamTtl9: "Advisor",
    teamName10: "Adam Vine",
    teamTtl10: "Narrative Design Director",
    teamName11: "Ben Abbott",
    teamTtl11: "Weapons Creator and Consultant ",
    teamName12: "Kamran Sadikhov",
    teamTtl12: "UI/UX Designer",
    teamTtl: "Team",
    partnersTtl: "Partners",
    backersTtl: "支持者",
    launchPartnTtl: "启动合作伙伴",
    techPartnTtl: "技术合作伙伴",
    comPartnTtl: "社区合作伙伴",
    presTtl: "如何购买",
    presStep1: "第1步",
    presStep2: "第二步",
    presStep3: "第3步",
    presStep1Desc:
      "首先，确保你的浏览器上安装了MetaMask钱包，或使用其中一个钱包连接到支持的钱包（我们推荐Trust Wallet）。<br/>从桌面浏览器购买会给你带来更顺畅的购买体验。为此我们推荐Metamask。<br/>如果你在手机上购买，我们建议使用Trust Wallet，并通过内置浏览器连接（只需复制https://swordsofblood.com 到Trust Wallet浏览器）。",
    presStep2Desc:
      "一旦你有了你想使用的钱包供应商，点击连接钱包并选择适当的选项。如果是手机钱包应用程序，您将需要选择钱包连接。",
    presStep3Desc: `你可以用ERC20 ETH、USDT、USDC和DAI购买${config.tokenSymbol}。在所有预售阶段结束后，您将可以通过此页面认领您的${config.tokenSymbol}。请等待我们的公告。`,

    date: {
      Days: "天数",
      Hours: "小时",
      Minutes: "分钟",
      Seconds: "秒",
    },
    timerLabel: "预售3结束在 :",
    investorTtl: "种子轮投资者",
  };
}
