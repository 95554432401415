import { config } from "../functions/config";

export default function Strings() {
  return {
    tokenAddress: "TOKEN ADDRESS",
    logoTitle: "Logo",
    aboutTtl: "Hakkında",
    wpTtl: "Whitepaper",
    allocTtl: "Jeton Tahsisi",
    deckTtl: "Pitch Deck",
    presaleTtl: "Ön Satış",
    rmTtl: "Yol Haritası",
    auditTtl: "Denetim",
    projectTtl: "Proje",
    quickLinksTtl: "Quick links",
    privPolicy: "Gizlilik Politikası",
    termsAndConds: "Şartlar ve Koşullar",
    amlPolicy: "Anti-Money Laundering Policy",
    sitemap: "Sitemap",
    salePolicy: `${config.tokenSymbol} Satış Hüküm ve Koşulları`,
    descText1:
      "Bu sürükleyici, mobil Aksiyon RPG oyununda krallığı anarşiden kurtarın! Antik yeraltı dünyasından gelen düşmanlarla sihirli büyüler ve şiddetli demir silahlar kullanarak savaşın! Güncel kalmak için sosyal medya hesaplarımızı takip edin!",
    descText2:
      "Copyright © 2022-2024 Hitbox Holdings SVG LLC, a St Vincent Corporation",
    descText3:
      "Suite 305 Griffith Corporate Centre, Kingstown, St Vincent, and the Grenadines.",
    quickLinks: "Quick links",
    // presaleStage: 'Presale stage',
    presaleStage: "SON ÖN SATIŞ TURU!",
    presaleEnded: "ÖN SATIŞ SONA ERDİ!",
    stayTuned: "Lütfen jeton talep talimatları için beklemeye devam edin.",
    thankyouForParticipating:
      "SWDTKN ÖN SATIŞINA KATILDIĞINIZ İÇİN TEŞEKKÜR EDERİZ!",
    // buyBeforeTheEnd: "Hurry before the end of presale!",
    buyBeforeTheEnd: "Please wait for announcements",
    buyBefore: "İDO duyuruları için takipte kalın. Lansman fiyatı:",
    soldTtl: "Sold",
    raisedTtl: "Raised",
    connectWallet: "Connect Wallet",
    minimumPurchWarn: `Purchase amount must be not less than {VALUE1} ${config.tokenSymbol} and no more than {VALUE2} ${config.tokenSymbol}.`,
    minimumPurchWarn2: `Purchase amount must be no more than {VALUE1} ${config.tokenSymbol}.`,
    presaleInactive: "Presale is inactive.",
    insufFunds: "You have not enough money to make a transaction.",
    impossToTx: "It is impossible to make a transaction.",
    invalidTime: "Invalid time.",
    stayAndWait:
      "Please stay on the website and wait for the transaction confirmation",
    goesWrong: "You canceled the operation or something goes wrong.",
    thankYou: "Thank you for your purchase!",
    tokPayFailed: "Token payment failed",
    claimNoFunds: "You have no tokens available for withdrawal.",
    statClaimed: `Your ${config.tokenSymbol} tokens will be sent to your address soon.`,
    connectNote:
      "*Please note that, this action can not be undone once the wallet is authorize.",
    connectTheWallet: "Connect the wallet",
    youOwn: "You own",
    buyWith: "Buy with",
    claimTokens: "Claim",
    buyTokenWith: `Buy ${config.tokenSymbol} with`,
    minimumTtl: "Minimum",
    buyDesc: `Enter the amount of ${config.tokenSymbol} you wish to purchase, then click "Buy"`,
    buyTtl: "Buy",
    payTtl: "Pay",
    benefit1: "MVP kapalı beta testine özel erişim",
    benefit2: "Geliştirici günlüğüne erişim",
    benefit3: "Sınırlı sayıda efsanevi \n oyun içi silah",
    benefit4: "Yaklaşan NFT satışları için otomatik beyaz listeye alma",
    benefit5: "Fiziksel dijital ödüllere katılım",
    benefit6: "Ve daha fazlası!",
    whybuyToken1:
      "MVP oynanabilir durumda ve beta sürümü Mayıs'ta kullanıma sunulacak",
    whybuyToken2: "Android ve iOS'ta toplam 4 milyon indirme",
    whybuyToken3: "MVP sürümü için 6 milyon dolar gelir",
    whybuyToken4:
      "2 yıllık kapsamlı oyun geliştirme genişleme ve kullanıcı edinme planı",
    whybuyToken5:
      "Ekip, oyun geliştirme konusunda tecrübeli olan veteranlardan oluşuyor",
    approveMessage: `Payment with stablecoins will require confirmation of two transactions: first to approve spending and second to ${config.tokenSymbol} purchase. Stay on the website until the all transactions are confirmed. Only ERC-20 tokens supported.`,
    wygTitle1: "App Store ve Playstore'da 4 milyondan fazla indirildi",
    wygTitle2: "Uygulama içi satın alımlardan 6 milyon USD'den fazla gelir",
    wygTitle3: "5 üzerinden 4,5 ortalama kullanıcı değerlendirmesi",
    wygTitle4: "250 saatten fazla kampanya oynanış süresi",
    wygTitle5: "Birden çok asenkron oyun modu mevcut",
    wygTitle6: "Web3 MVP Eylül 2024'te piyasaya çıkacak.",
    wygTitle7: "Proje oyun geliştirme veterenleri tarafından yönetiliyor",
    wygTitle8: "Mobil ve PC için çapraz oynama özelliği",
    wygTitle9:
      "16 oyunculu çok oyunculu PvP haritası ile yüksek derecede kendine çeken bir oyun",
    wygTitle10: "4k oyun deneyiminde 60fps keyfi (PC'de)",
    modeT1: "Canavar Avı",
    modeT2: "Patron Kalesi",
    modeT3: "PvP",
    modeT4: "Günlük Zindanlar",
    modeT5: "Etkinlik Zindanı",
    modeT6: "Kampanya",
    modeInfo:
      "Swords of Blood'un MVP'si birden fazla oyun modu sunuyor! Mayıs ayında başlayacak kapalı beta erişimi için devam eden etkinliğe katılın.",
    aboutDesc1: `Gösterişli dövüşler, mükemmel grafikler ve çeşitli oyun modları sunan ${config.appName}, Ape Chain'da lansmanı yapılan blok zincirindeki ilk AAA kalitesinde hızlı tempolu F2P hack-and-slash RPG'dir.`,
    aboutDesc2:
      "Şiddet, ihanet ve kurnaz kötülük Ezura dünyasının acımasız çöküşüne neden oldu. Muazzam kötülüğe sahip bir varlık olan Hain, diyarın sadık koruyucularını yok etti ve 1.000 yıldır karanlık hüküm sürüyor.",
    aboutDesc3:
      "It is a time of desperation, despair, and death, as the Betrayer’s armies cover the land.",
    aboutDesc4:
      "But from the ruins, a possible salvation arises; the last of the once mighty warrior order has been resurrected by an ancient goddess known as the Sentinel. Together, you will attempt to bring a spark of hope to the desolate land.",
    aboutDesc5:
      "But from the ruins, possible salvation arises; the last of the once mighty warrior order has been resurrected by an ancient goddess known as the Sentinel. Together, you will attempt to bring a spark of hope to the desolate land.",
    contactUsDesc: `${config.appName} elçisi olun ve oyun jetonları, altın ve mücevherler kazanın.!`,
    contactUs: "Contact Us",
    asSeenOnTtl: "Görüldüğü gibi",
    facTtl1: "Ezura'nın güzel dünyasını keşfedin",
    facTtl2: "Çok çeşitli silah ve unsurlarda ustalaşın",
    facTtl3: "Yıkıcı saldırı kombinasyonları oluşturun",
    facTtl4: "Benzersiz, güçlü dişliler ve silahlar üretin",
    facTtl5: "Rakiplerinizi öldürmek için hack n slash oyununu kullanın",
    facTtl6: "Hızlı tempolu, acımasız bir PvP'de diğer oyuncularla yarışın",
    teamName1: "James Seaman",
    teamTtl1: "CEO",
    teamBio1:
      "The Sims, Sims City, Ghost Recon, Rainbow Six ve daha fazlası gibi oyun başlıklarında programlamadan CEO'luğa kadar birçok seviyede çalışmış, video oyun endüstrisinin 36 yıllık emektarı.",
    teamName2: "Jeremy Brown",
    teamTtl2: "COO",
    teamBio2:
      "Sony PlayStation ve Xbox pazarlama hesaplarının yanı sıra Halo, Call of Duty, Assassin's Creed ve Tom Clancy serileri dahil olmak üzere en ikonik oyun başlıklarından bazılarında kapsamlı bir şekilde çalıştı.",
    teamName3: "Mariusz Szynalik",
    teamTtl3: "Project Director for HitBox Games",
    teamBio3:
      "Video oyunları yayıncılığı ve PC/konsol ve mobil Free-To-Play ve blockchain özellikli oyunların satışına odaklanan bir oyun yöneticisi olarak 10 yılı aşkın deneyim. Artifex Mundi S.A.'nın kurucu ortağı",
    teamName4: "Yupeng Qin",
    teamTtl4: "Project Director for Asian Dev Team",
    teamBio4:
      "Topluluk oluşturma, pazarlama, halk ve müşteri ilişkileri, içerik yazma ve oluşturma ve web3, gamefi ve defi projeleri de dahil olmak üzere sosyal medya yönetimi alanlarında on yılı aşkın deneyim.",
    teamName5: "Jakub Szamalek",
    teamTtl5: "Narrative Director",
    teamBio5: "A decade of experience in UI/UX design",
    teamName6: "John Moyer",
    teamTtl6: "Bass Guitarist of the band Disturbed",
    teamBio6:
      "Former Director of Narrative Design for Cyberpunk and The Witcher 3",
    teamName7: "Vee Lozano",
    teamTtl7: "Business Development Lead",
    teamBio7:
      "Star Judge and world champion bladesmith on the History Channel’s hit show, Forged in Fire",
    teamName8: "Jason Hung",
    teamTtl8: "Advisor",
    teamBio8:
      "Lead writer for the upcoming megahit: Gord Author and Hollywood Screenplay Writer",
    teamName9: "Charlie Hu",
    teamTtl9: "Advisor",
    teamName10: "Adam Vine",
    teamTtl10: "Narrative Design Director",
    teamName11: "Ben Abbott",
    teamTtl11: "Weapons Creator and Consultant ",
    teamName12: "Kamran Sadikhov",
    teamTtl12: "UI/UX Designer",
    teamTtl: "Team",
    partnersTtl: "Partners",
    backersTtl: "Destekçiler",
    launchPartnTtl: "Lansman Ortakları",
    techPartnTtl: "Teknoloji Ortakları",
    comPartnTtl: "Topluluk Ortakları",
    presTtl: "Nasıl satın alınır",
    presStep1: "ADIM 1",
    presStep2: "ADIM 2",
    presStep3: "ADIM 3",
    presStep1Desc:
      "Öncelikle, tarayıcınızda MetaMask cüzdanının yüklü olduğundan emin olun veya desteklenen cüzdanlardan birine bağlanmak için cüzdanlardan birini kullanın (Trust Wallet'ı öneririz).<br/>Bir masaüstü tarayıcısından satın almak size daha sorunsuz bir satın alma deneyimi sağlayacaktır. Bunun için Metamask'ı öneriyoruz.<br/>Bir cep telefonundan satın alıyorsanız, Trust Wallet'ı kullanmanızı ve yerleşik tarayıcı üzerinden bağlanmanızı öneririz (https://swordsofblood.com adresini Trust Wallet tarayıcısına kopyalamanız yeterlidir).",
    presStep2Desc:
      "Kullanmak istediğiniz cüzdan sağlayıcısını belirledikten sonra Cüzdan Bağlantısı'na tıklayın ve uygun seçeneği belirleyin. Bir mobil cüzdan uygulaması söz konusu olduğunda, Cüzdan Bağlantısı'nı seçmeniz gerekecektir.",
    presStep3Desc: `ERC20 ETH, USDT, USDC ve DAI ile ${config.tokenSymbol} satın alabilirsiniz. Tüm ön satış aşamaları bittikten sonra, ${config.tokenSymbol}'nizi bu sayfa üzerinden talep edebileceksiniz. Lütfen duyurumuzu bekleyin. `,

    date: {
      Days: "Days",
      Hours: "Hours",
      Minutes: "Minutes",
      Seconds: "Seconds",
    },
    timerLabel: "Presale 3 ends in:",
    investorTtl: "Tohum Turu Yatırımcıları",
  };
}
