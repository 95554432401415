import { config } from "../functions/config";

export default function Strings() {
  return {
    tokenAddress: "TOKEN-ADRESSE",
    logoTitle: "Logo",
    aboutTtl: "Über",
    wpTtl: "Whitepaper",
    allocTtl: "Token-Zuteilung",
    deckTtl: "Pitchdeck",
    presaleTtl: "Vorverkauf",
    rmTtl: "Fahrplan",
    auditTtl: "Prüfung",
    projectTtl: "Projekt",
    quickLinksTtl: "Quick links",
    privPolicy: "Datenschutz",
    termsAndConds: "Bedingungen und Konditionen",
    amlPolicy: "Anti-Geldwäsche-Politik",
    sitemap: "Seitenverzeichnis",
    salePolicy: `${config.tokenSymbol} Verkaufsbedingungen`,
    descText1:
      "Save the realm from anarchy in this immersive, mobile Action RPG! Fight enemies from the ancient underworld using magic spells and fierce iron weapons! Follow our socials to stay updated!",
    descText2:
      "Copyright © 2022-2024 Hitbox Holdings SVG LLC, a St Vincent Corporation",
    descText3:
      "Suite 305 Griffith Corporate Centre, Kingstown, St Vincent, and the Grenadines.",
    quickLinks: "Quick links",
    // presaleStage: "Vorverkaufsphase",
    presaleStage: "LETZTE VORVERKAUFSRUNDE!",
    presaleEnded: "VORBESTELLUNG BEENDET!",
    stayTuned:
      "Bitte bleiben Sie gespannt auf die Anweisungen zur Token-Einlösung.",
    thankyouForParticipating:
      "DANKE, DASS SIE AN DER SWDTKN VORBESTELLUNG TEILGENOMMEN HABEN!",
    // buyBeforeTheEnd: "Beeilen Sie sich vor dem Ende des Vorverkaufs!",
    buyBeforeTheEnd: "Bitte warten Sie auf Ankündigungen",
    buyBefore: "Bleiben Sie dran für IDO-Ankündigungen. Einführungspreis:",
    soldTtl: "Verkauft",
    raisedTtl: "Gesammelt",
    connectWallet: "Verbinde das Wallet",
    minimumPurchWarn: `Der Kaufbetrag darf nicht weniger als {VALUE1} ${config.tokenSymbol} und nicht mehr als {VALUE2} ${config.tokenSymbol} betragen.`,
    minimumPurchWarn2: `Der Kaufbetrag darf nicht höher sein als {VALUE1} ${config.tokenSymbol}.`,
    presaleInactive: "Der Vorverkauf ist inaktiv.",
    insufFunds:
      "Sie haben nicht genug Geld, um eine Transaktion durchzuführen.",
    impossToTx: "Es ist unmöglich, eine Transaktion durchzuführen.",
    invalidTime: "Ungültige Zeit.",
    stayAndWait:
      "Bitte verlasse die Seite nicht und warte auf die Bestätigung der Transaktion.",
    goesWrong:
      "Du hast die Transaktion abgebrochen oder ein Problem ist aufgetreten.",
    thankYou: "Vielen Dank für Ihren Kauf!",
    tokPayFailed: "Token-Zahlung fehlgeschlagen",
    claimNoFunds: "Du hast keine verfügbaren Token für die Auszahlung.",
    statClaimed: `Deine ${config.tokenSymbo}-Tokens werden in Kürze an deine Adresse gesendet.`,
    connectNote:
      "*Please note that, this action can not be undone once the wallet is authorize.",
    connectTheWallet: "Connect the wallet",
    youOwn: "Sie besitzen",
    buyWith: "Kaufen mit",
    claimTokens: "Anspruch",
    buyTokenWith: `Kaufen ${config.tokenSymbol} mit`,
    minimumTtl: "Minimum",
    buyDesc: `Geben Sie den Betrag von ${config.tokenSymbol} ein, den Sie kaufen möchten, und klicken Sie dann auf "Kaufen".`,
    buyTtl: "Kaufen",
    payTtl: "Bezahlen",
    benefit1: "Exklusiver Zugang zum MVP Closed Beta-Test",
    benefit2: "Zugang zum Entwicklertagebuch",
    benefit3: "Limitierte Legendäre \n In-Game-Waffe",
    benefit4: "Automatische Whitelisting für kommende NFT-Verkäufe",
    benefit5: "Teilnahme an phygitalen Giveaways",
    benefit6: "Und mehr!",
    whybuyToken1: "MVP ist spielbar und Beta wird im Mai live gehen",
    whybuyToken2: "4 Millionen Downloads auf Android und iOS zusammen",
    whybuyToken3: "6 Millionen Umsatz für MVP-Version",
    whybuyToken4:
      "2-jähriger umfassender Plan für die Erweiterung der Spieleentwicklung und Benutzerakquise",
    whybuyToken5: "Das Team besteht aus Veteranen der Spieleentwicklung",
    approveMessage: `Wenn du mit Stablecoins bezahlen willst, musst du zwei Transaktionen bestätigen: Zuerst, um Ausgaben zu autorisieren, und dann, um ${config.tokenSymbol} zu kaufen. Bleibe auf der Seite, bis alle Transaktionen bestätigt wurden. Es werden nur ERC-20-Tokens unterstützt.`,
    wygTitle1: "Ein dynamisches Hack n Slash RPG",
    wygTitle2: "Äußerst fesselndes, wunderschön gerendertes Gameplay",
    wygTitle3: "PvE- und PvP-Modi",
    wygTitle4: "Mehr als 250 Stunden Spielzeit in der Kampagne",
    wygTitle5: "Mehrere asynchrone Spielmodi verfügbar",
    wygTitle6: "Web3 MVP erscheint im September 2024.",
    wygTitle7: "Das Projekt wird von Spieleentwicklungsveteranen geleitet",
    wygTitle8: "Cross-Play für Handy und PC möglich",
    wygTitle9: "Hochgradig immersive 16-Spieler-Multiplayer-PvP-Karte",
    wygTitle10: "Genießen Sie 60fps in 4k-Spielerfahrung (PC)",
    modeT1: "Monsterjagd",
    modeT2: "Boss-Festung",
    modeT3: "PvP",
    modeT4: "Tägliche Dungeons",
    modeT5: "Event-Dungeon",
    modeT6: "Kampagne",
    modeInfo:
      "Swords of Bloods MVP bietet mehrere Spielmodi! Erhalten Sie exklusiven Zugang zur geschlossenen Beta (Start im Mai), indem Sie an der laufenden",
    aboutDesc1: `${config.appName} ist das erste rasante F2P-Hack-and-Slash-RPG in AAA-Qualität auf der Blockchain, das auf Ape Chain gestartet wurde. Es bietet aufregende Kämpfe, hervorragende Grafik und verschiedene Spielmodi.`,
    aboutDesc2:
      "Gewalt, Verrat und listiges Böses haben den brutalen Untergang der Welt von Ezura herbeigeführt. Der Verräter, ein Wesen von unermesslichem Übel, hat die treuen Beschützer des Reiches ausgelöscht und seit 1.000 Jahren herrscht Dunkelheit.",
    aboutDesc3:
      "Es ist eine Zeit der Verzweiflung, der Hoffnungslosigkeit und des Todes, in der die Armeen des Verräters das Land überziehen.",
    aboutDesc4:
      "Doch aus den Ruinen erhebt sich eine mögliche Rettung: Der letzte des einst mächtigen Kriegerordens wurde von einer alten Göttin, der Wächterin, wiedererweckt. Gemeinsam werdet ihr versuchen, einen Funken Hoffnung in das trostlose Land zu bringen.",
    aboutDesc5:
      "Werden Sie ein furchtloser Dungeon-Crawler, ein kopfgeldjagender Kampfmagier oder ein mächtiger Krieger, der nach Beute und seltener Ausrüstung sucht. Stellen Sie epische Ausrüstung her, beugen Sie die Magie der Natur nach Ihrem Willen und rächen Sie Ihre gefallenen Brüder in einem epischen Abenteuer der Macht und Rache.",
    contactUsDesc: `Werde ein ${config.appName}-Botschafter und verdiene Spielmarken, Gold und Edelsteine.`,
    contactUs: "Kontaktiere uns",
    asSeenOnTtl: "Gesehen bei",
    facTtl1: "Entdecke die wunderschöne Welt von Ezura",
    facTtl2: "Meistern Sie eine Vielzahl von Waffen und Elementen",
    facTtl3: "Erstelle verheerende Angriffskombinationen",
    facTtl4: "Fertige einzigartige, mächtige Ausrüstungen und Waffen",
    facTtl5: `Töte deine Gegner mit Hack'n'Slash-Gameplay`,
    facTtl6: "Messe dich mit anderen Spielern in einem rasanten, brutalen PvP",
    teamBio1:
      "A 36-year veteran of the video game industry who has worked on game titles, such as The Sims, Sims City, Ghost Recon, Rainbow Six, and more), on many levels - from programming to CEO.",
    teamBio2:
      "Worked extensively on the Sony PlayStation and Xbox marketing accounts as well as some of the most iconic game titles including Halo, Call of Duty, Assassin’s Creed and the Tom Clancy franchises.",
    teamBio3:
      "Over 10 years of experience as a gaming executive focused on video games publishing and sales of PC/consoles and mobile Free-To-Play and blockchain-enabled games. Cofounder of Artifex Mundi S.A.",
    teamBio4:
      "Over ten years of experience in community building, marketing, public and client relations, content writing andcreation, and social media management including projects in web3, gamefi, and defi.",
    teamBio5: "A decade of experience in UI/UX design",
    teamBio6:
      "Former Director of Narrative Design for Cyberpunk and The Witcher 3",
    teamBio7:
      "Star Judge and world champion bladesmith on the History Channel’s hit show, Forged in Fire",
    teamBio8:
      "Lead writer for the upcoming megahit: Gord Author and Hollywood Screenplay Writer",
    teamName1: "James Seaman",
    teamTtl1: "CEO",
    teamName2: "Jeremy Brown",
    teamTtl2: "COO",
    teamName3: "Mariusz Szynalik",
    teamTtl3: "Project Director for HitBox Games",
    teamName4: "Yupeng Qin",
    teamTtl4: "Project Director for Asian Dev Team",
    teamName5: "Jakub Szamalek",
    teamTtl5: "Narrative Director",
    teamName6: "John Moyer",
    teamTtl6: "Bass Guitarist of the band Disturbed",
    teamName7: "Vee Lozano",
    teamTtl7: "Business Development Lead",
    teamName8: "Jason Hung",
    teamTtl8: "Advisor",
    teamName9: "Charlie Hu",
    teamTtl9: "Advisor",
    teamName10: "Adam Rebe",
    teamTtl10: "Narrative Design-Direktor",
    teamName11: "Ben Abbott",
    teamTtl11: "Waffenschöpfer und -berater",
    teamName12: "Kamran Sadikhov",
    teamTtl12: "UI/UX Designer",
    teamTtl: "Team",
    partnersTtl: "Partner",
    backersTtl: "Unterstützer",
    launchPartnTtl: "Start-Partner",
    techPartnTtl: "Technologie-Partner",
    comPartnTtl: "Partner der Gemeinschaft",
    presTtl: "Wie man kauft",
    presStep1: "SCHRITT 1",
    presStep2: "SCHRITT 2",
    presStep3: "SCHRITT 3",
    presStep1Desc:
      "Stellen Sie zunächst sicher, dass Sie die MetaMask-Brieftasche in Ihrem Browser installiert haben, oder verwenden Sie eine der Brieftaschen, um sich mit einer der unterstützten Brieftaschen zu verbinden (wir empfehlen Trust Wallet). <br>Der Kauf von einem Desktop-Browser aus bietet Ihnen ein reibungsloseres Kauferlebnis. Hierfür empfehlen wir Metamask. <br>Wenn Sie mit einem Mobiltelefon einkaufen, empfehlen wir Trust Wallet zu verwenden und die Verbindung über den integrierten Browser herzustellen (kopieren Sie einfach https://swordsofblood.com in den Trust Wallet-Browser).",
    presStep2Desc:
      "Sobald Sie den gewünschten Wallet-Anbieter ausgewählt haben, klicken Sie auf Connect Wallet und wählen die entsprechende Option. Im Falle einer mobilen Wallet-App müssen Sie Wallet Connect auswählen.",
    presStep3Desc: `Sie können ${config.tokenSymbol} mit ERC20 ETH, USDT, USDC und DAI kaufen. Nachdem alle Vorverkaufsphasen abgeschlossen sind, können Sie Ihren ${config.tokenSymbol} über diese Seite anfordern. Bitte warten Sie unsere Ankündigung ab.`,

    date: {
      Days: "Tage",
      Hours: "Std",
      Minutes: "Protokoll",
      Seconds: "Sekunden",
    },
    timerLabel: "Vorverkauf 3 endet in : ",
    investorTtl: "Investoren der Seed-Runde",
  };
}
