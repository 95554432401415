import { config } from "../functions/config";

export default function Strings() {
  return {
    tokenAddress: "TOKEN ADDRESS",
    logoTitle: "Logo",
    aboutTtl: "О компании",
    wpTtl: "Whitepaper",
    allocTtl: "Распределение токенов",
    deckTtl: "Питч-дек",
    presaleTtl: "Предпродажа",
    rmTtl: "Дорожная карта",
    auditTtl: "Аудит",
    projectTtl: "Проект",
    quickLinksTtl: "Quick links",
    privPolicy: "Политика конфиденциальности",
    termsAndConds: "Правила и условия",
    amlPolicy: "Политика по борьбе с отмыванием денег",
    sitemap: "Sitemap",
    salePolicy: `Правила и условия продажи ${config.tokenSymbol}`,
    descText1:
      "Спасите королевство от анархии в этой захватывающей мобильной Action RPG! Сражайтесь с врагами из древнего подземного мира, используя магические заклинания и свирепое железное оружие! Следите за нашими социальными сетями, чтобы оставаться в курсе событий!",
    descText2:
      "Copyright © 2022-2024 Hitbox Holdings SVG LLC, a St Vincent Corporation",
    descText3:
      "Suite 305 Griffith Corporate Centre, Kingstown, St Vincent, and the Grenadines.",
    quickLinks: "Quick links",
    // presaleStage: 'Presale stage',
    presaleStage: "ФИНАЛЬНЫЙ РАУНД ПРЕДПРОДАЖИ!",
    presaleEnded: "ПРЕДПРОДАЖА ЗАКОНЧЕНА!",
    stayTuned: "Пожалуйста, следите за инструкциями по получению токена.",
    thankyouForParticipating: "СПАСИБО ЗА УЧАСТИЕ В ПРЕДПРОДАЖЕ SWDTKN!",
    // buyBeforeTheEnd: "Hurry before the end of presale!",
    buyBeforeTheEnd: "Please wait for announcements",
    buyBefore: "Следите за объявлениями IDO. Стартовая цена:",
    soldTtl: "ПРОДАНО",
    raisedTtl: "ПОВЫШЕНА",
    connectWallet: "Connect Wallet",
    minimumPurchWarn: `Purchase amount must be not less than {VALUE1} ${config.tokenSymbol} and no more than {VALUE2} ${config.tokenSymbol}.`,
    minimumPurchWarn2: `Purchase amount must be no more than {VALUE1} ${config.tokenSymbol}.`,
    presaleInactive: "Presale is inactive.",
    insufFunds: "You have not enough money to make a transaction.",
    impossToTx: "It is impossible to make a transaction.",
    invalidTime: "Invalid time.",
    stayAndWait:
      "Please stay on the website and wait for the transaction confirmation",
    goesWrong: "You canceled the operation or something goes wrong.",
    thankYou: "Thank you for your purchase!",
    tokPayFailed: "Token payment failed",
    claimNoFunds: "You have no tokens available for withdrawal.",
    statClaimed: `Your ${config.tokenSymbol} tokens will be sent to your address soon.`,
    connectNote:
      "*Please note that, this action can not be undone once the wallet is authorize.",
    connectTheWallet: "Connect the wallet",
    youOwn: "You own",
    buyWith: "Buy with",
    claimTokens: "Claim",
    buyTokenWith: `Buy ${config.tokenSymbol} with`,
    minimumTtl: "Minimum",
    buyDesc: `Enter the amount of ${config.tokenSymbol} you wish to purchase, then click "Buy"`,
    buyTtl: "Buy",
    payTtl: "Pay",
    benefit1: "Эксклюзивный доступ к закрытому бета-тесту MVP",
    benefit2: "Доступ к дневнику разработки",
    benefit3: "Ограниченное Легендарное оружие в игре",
    benefit4:
      "Автоматическое добавление в белый список для предстоящих продаж NFT",
    benefit5: "Возможность участия в раздачах физических и цифровых призов",
    benefit6: "И многое другое!",
    whybuyToken1: "MVP играбельна, бета-версия выйдет в мае",
    whybuyToken2: "4 миллиона загрузок на Android и iOS вместе",
    whybuyToken3: "6 миллионов дохода за версию MVP",
    whybuyToken4:
      "2-летний комплексный план для расширения разработки игр и привлечения пользователей",
    whybuyToken5: "Команда состоит из ветеранов разработки игр",
    approveMessage: `Payment with stablecoins will require confirmation of two transactions: first to approve spending and second to ${config.tokenSymbol} purchase. Stay on the website until the all transactions are confirmed. Only ERC-20 tokens supported.`,
    wygTitle1: "Более 4 миллионов загрузок в App Store и Playstore",
    wygTitle2: "6M+ USD дохода от покупок внутри приложений",
    wygTitle3: "4,8 из 5-звездочных средних оценок пользователей",
    wygTitle4: "250+ часов игрового процесса кампании",
    wygTitle5: "Доступно несколько асинхронных режимов игры",
    wygTitle6: "web3 MVP стартует в сентябре 2024 года.",
    wygTitle7: "Проектом руководят ветераны разработки игр",
    wygTitle8: "Cross-play enabled for mobile and PC",
    wygTitle9: "Высоко-иммерсивная мультиплеерная PvP-карта для 16 игроков",
    wygTitle10:
      "Наслаждайтесь игровым процессом в 60 кадрах в секунду в разрешении 4K (PC)",
    modeT1: "Охота на монстров",
    modeT2: "Крепость Боссов",
    modeT3: "Игрок против игрока",
    modeT4: "Ежедневные подземелья",
    modeT5: "Событийное подземелье",
    modeT6: "Кампания",
    modeInfo:
      "MVP Swords of Blood предлагает множество игровых режимов! Получите эксклюзивный доступ к закрытой бете (запуск в мае), участвуя в текущей",
    aboutDesc1: `Хвастаясь яркими боями, звездной графикой и различными игровыми режимами, ${config.appName} - это первая ролевая игра F2P hack-and-slash на блокчейне качества ААА с быстрым темпом, которую запускает Ape Chain.`,
    aboutDesc2:
      "Насилие, предательство и коварное зло привели к жестокому падению мира Эзуры. Предатель, существо огромного зла, уничтожил стойких защитников королевства, и на 1000 лет воцарилась тьма.",
    aboutDesc3:
      "Это время отчаяния, безысходности и смерти, когда армии Предателя покрывают землю.",
    aboutDesc4:
      "Но из руин появляется возможность спасения: последний представитель некогда могущественного ордена воинов был воскрешен древней богиней, известной как Страж. Вместе вы попытаетесь принести искру надежды в опустевшую землю.",
    aboutDesc5:
      "Станьте бесстрашным обитателем подземелий, боевым магом, охотящимся за головами, или могучим воином, добывая добычу и редкое снаряжение. Создавайте эпическое снаряжение, склоняйте магию природы на свою волю и отомстите за своих павших братьев в эпическом походе к могуществу и мести.",
    contactUsDesc: `Станьте представителем ${config.appName} и зарабатывайте игровые жетоны, золото и драгоценные камни.`,
    contactUs: "Contact Us",
    asSeenOnTtl: "Как видно на",
    facTtl1: "Откройте для себя прекрасный мир Эзуры",
    facTtl2: "Овладейте широким разнообразием оружия и элементов",
    facTtl3: "Создавайте разрушительные комбинации атак",
    facTtl4: "Создавайте уникальные, мощные механизмы и оружие",
    facTtl5: `Используйте геймплей "взлома и разрушения", чтобы уничтожить своих противников`,
    facTtl6: "Соревнуйтесь с другими игроками в стремительном и жестоком PvP.",
    teamName1: "James Seaman",
    teamTtl1: "CEO",
    teamBio1:
      "36-летний ветеран индустрии видеоигр, работавший над такими играми, как The Sims, Sims City, Ghost Recon, Rainbow Six и др.), на разных уровнях - от программирования до генерального директора.",
    teamName2: "Jeremy Brown",
    teamTtl2: "COO",
    teamBio2:
      " Много работал над маркетинговыми счетами Sony PlayStation и Xbox, а также над некоторыми из самых знаковых игр, включая Halo, Call of Duty, Assassin's Creed и франшизы Tom Clancy.",
    teamName3: "Mariusz Szynalik",
    teamTtl3: "Project Director for HitBox Games",
    teamBio3:
      "Более 10 лет опыта работы в качестве руководителя игрового бизнеса, сосредоточенного на издании видеоигр и продажах игр для ПК/консолей и мобильных Free-To-Play и игр с поддержкой блокчейна. Соучредитель компании Artifex Mundi S.A.",
    teamName4: "Yupeng Qin",
    teamTtl4: "Project Director for Asian Dev Team",
    teamBio4:
      " Более десяти лет опыта в создании сообществ, маркетинге, связях с общественностью и клиентами, написании и создании контента и управлении социальными медиа, включая проекты в web3, gamefi и defi.",
    teamName5: "Jakub Szamalek",
    teamTtl5: "Narrative Director",
    teamBio5: "A decade of experience in UI/UX design",
    teamName6: "John Moyer",
    teamTtl6: "Bass Guitarist of the band Disturbed",
    teamBio6:
      "Former Director of Narrative Design for Cyberpunk and The Witcher 3",
    teamName7: "Vee Lozano",
    teamTtl7: "Business Development Lead",
    teamBio7:
      "Star Judge and world champion bladesmith on the History Channel’s hit show, Forged in Fire",
    teamName8: "Jason Hung",
    teamTtl8: "Advisor",
    teamBio8:
      "Lead writer for the upcoming megahit: Gord Author and Hollywood Screenplay Writer",
    teamName9: "Charlie Hu",
    teamTtl9: "Advisor",
    teamName10: "Adam Vine",
    teamTtl10: "Narrative Design Director",
    teamName11: "Ben Abbott",
    teamTtl11: "Weapons Creator and Consultant ",
    teamName12: "Kamran Sadikhov",
    teamTtl12: "UI/UX Designer",
    teamTtl: "Team",
    partnersTtl: "Partners",
    backersTtl: "Бэкеры",
    launchPartnTtl: "Партнеры по запуску",
    techPartnTtl: "Технологические партнеры",
    comPartnTtl: "Партнеры по сообществу",
    presTtl: "Как купить",
    presStep1: "ШАГ 1",
    presStep2: "ШАГ 2",
    presStep3: "ШАГ 3",
    presStep1Desc:
      "Во-первых, убедитесь, что в вашем браузере установлен кошелек MetaMask, или используйте один из кошельков для подключения к одному из поддерживаемых кошельков (мы рекомендуем Trust Wallet).<br/>Покупка через настольный браузер обеспечит вам более плавный процесс покупки. Для этого мы рекомендуем Metamask.<br/>Если вы покупаете на мобильном телефоне, мы рекомендуем использовать Trust Wallet и подключиться через встроенный браузер (просто скопируйте https://swordsofblood.com в браузер Trust Wallet).",
    presStep2Desc:
      "После того как вы выбрали поставщика кошелька, который хотите использовать, нажмите кнопку Подключить кошелек и выберите соответствующую опцию. В случае с приложением мобильного кошелька вам нужно выбрать Wallet Connect.",
    presStep3Desc: `Вы можете приобрести ${config.tokenSymbol} с помощью ERC20 ETH, USDT, USDC и DAI. После завершения всех этапов предпродажи вы сможете получить свои ${config.tokenSymbol} через эту страницу. Пожалуйста, дождитесь нашего объявления.`,

    date: {
      Days: "ДНИ",
      Hours: "ЧАСЫ",
      Minutes: "МИНУТЫ",
      Seconds: "СЕКУНДЫ",
    },
    timerLabel: "Предпродажа 3 заканчивается в :",
    investorTtl: "Инвесторы посевного раунда",
  };
}
