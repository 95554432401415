import { config } from "../functions/config";

export default function Strings() {
  return {
    tokenAddress: "ADRESSE DU JETON",
    logoTitle: "Logo",
    aboutTtl: "À propos de",
    wpTtl: "Whitepaper",
    allocTtl: "Allocation de jetons",
    deckTtl: "Pitchdeck",
    presaleTtl: "Prévente",
    rmTtl: "Feuille de route",
    auditTtl: "Audit",
    projectTtl: "Projet",
    quickLinksTtl: "Quick links",
    privPolicy: "Politique de confidentialité",
    termsAndConds: "Conditions générales d'utilisation",
    amlPolicy: "Politique anti-blanchiment d'argent",
    sitemap: "Plan du site",
    salePolicy: `Conditions de vente de ${config.tokenSymbol}`,
    descText1:
      "Save the realm from anarchy in this immersive, mobile Action RPG! Fight enemies from the ancient underworld using magic spells and fierce iron weapons! Follow our socials to stay updated!",
    descText2:
      "Copyright © 2022-2024 Hitbox Holdings SVG LLC, a St Vincent Corporation",
    descText3:
      "Suite 305 Griffith Corporate Centre, Kingstown, St Vincent, and the Grenadines.",
    quickLinks: "Quick links",
    // presaleStage: 'Phase de prévente',
    presaleStage: "TOUR DE PRÉVENTE FINAL !",
    presaleEnded: "PRÉVENTE TERMINÉE !",
    stayTuned:
      "Veuillez rester à l'écoute des instructions pour la réclamation des jetons.",
    thankyouForParticipating: "MERCI D'AVOIR PARTICIPÉ À LA PRÉVENTE SWDTKN !",
    // buyBeforeTheEnd: "Dépêchez-vous avant la fin de la prévente!",
    buyBeforeTheEnd: "Veuillez attendre les annonces",
    buyBefore: "Restez à l'affût des annonces IDO. Prix de lancement :",

    soldTtl: "Vendu",
    raisedTtl: "Collecté",
    connectWallet: "Connecter le portefeuille",
    minimumPurchWarn: `Le montant de l'achat ne doit pas être inférieur à {VALUE1} ${config.tokenSymbol} ni supérieur à {VALUE2} ${config.tokenSymbol}.`,
    minimumPurchWarn2: `DLe montant de l'achat ne doit pas être supérieur à {VALUE1} ${config.tokenSymbol}.`,
    presaleInactive: "La prévente est inactive.",
    insufFunds:
      "Vous n'avez pas assez d'argent pour effectuer une transaction.",
    impossToTx: "Il est impossible d'effectuer une transaction.",
    invalidTime: "Heure non valide.",
    stayAndWait: `Merci de ne pas quitter le site et d'attendre la confirmation de la transaction.`,
    goesWrong: `Tu as annulé l'opération ou un problème est survenu.`,
    thankYou: "Merci pour votre achat!",
    tokPayFailed: "Échec du paiement par jetons",
    claimNoFunds: `Tu n'as pas de tokens disponibles pour le retrait.`,
    statClaimed: `Tes tokens ${config.tokenSymbo} vont bientôt être envoyés à ton adresse.`,
    connectNote:
      "*Please note that, this action can not be undone once the wallet is authorize.",
    connectTheWallet: "Connect the wallet",
    youOwn: "Vous avez",
    buyWith: "Acheter avec",
    claimTokens: "Réclamation",
    buyTokenWith: `Acheter ${config.tokenSymbol} avec`,
    minimumTtl: "Minimum",
    buyDesc: `Entrez le montant de ${config.tokenSymbol} que vous souhaitez acheter, puis cliquez sur "Acheter"`,
    buyTtl: "Acheter",
    payTtl: "Payer",
    benefit1: "Accès exclusif à la version bêta fermée MVP",
    benefit2: "Accès au journal de développement",
    benefit3: "Arme légendaire limitée\nen jeu",
    benefit4: "Liste blanche automatique pour les prochaines ventes NFT",
    benefit5: "Participation aux cadeaux physiques et numériques",
    benefit6: "Et plus encore !",
    whybuyToken1: "MVP est jouable et la version bêta sera lancée en mai",
    whybuyToken2: "4 millions de téléchargements combinés sur Android et iOS",
    whybuyToken3: "6 millions de revenus pour la version MVP",
    whybuyToken4:
      "Plan complet de 2 ans pour l'expansion du développement de jeux et l'acquisition d'utilisateurs",
    whybuyToken5: "L'équipe est composée de vétérans du développement de jeux",
    approveMessage: `Pour payer avec des stablecoins, il est nécessaire de confirmer deux transactions : tout d'abord pour autoriser les dépenses et ensuite pour acheter des ${config.tokenSymbol}. Reste sur le site jusqu'à ce que toutes les transactions soient confirmées. Les tokens ERC-20 sont uniquement pris en charge.`,
    wygTitle1: "Un RPG hack n slash dynamique",
    wygTitle2: "Un gameplay très immersif et magnifiquement rendu",
    wygTitle3: "Modes PvE et PvP",
    wygTitle4: "Plus de 250 heures de campagne",
    wygTitle5: "Plusieurs modes de jeu asynchrones disponibles",
    wygTitle6: "Lancement du MVP web3 en septembre 2023",
    wygTitle7: "Le projet est dirigé par des vétérans du développement de jeux",
    wygTitle8: "Possibilité de cross-play pour mobile et PC",
    wygTitle9: "Carte JcJ multijoueur 16 joueurs hautement immersive",
    wygTitle10: "Profitez d'une expérience de jeu à 60fps en 4k (PC)",
    modeT1: "Chasse aux Monstres",
    modeT2: "Forteresse de Boss",
    modeT3: "JcJ",
    modeT4: "Donjons Quotidiens",
    modeT5: "Donjon d'Événement",
    modeT6: "Campagne",
    modeInfo:
      "L'MVP de Swords of Blood comprend plusieurs modes de jeu ! Obtenez un accès exclusif à la bêta fermée (qui sera lancée en mai) en participant à l'",
    aboutDesc1: `Avec ses combats spectaculaires, ses graphismes époustouflants et ses différents modes de jeu, ${config.appName} est le premier RPG de qualité AAA à rythme rapide de type F2P sur la blockchain, lancé par Ape Chain.`,
    aboutDesc2: `La violence, la trahison et la malveillance ont entraîné la chute brutale du monde d'Ezura. Le Traître, un être d'un mal immense, a anéanti les vaillants protecteurs du royaume et depuis 1 000 ans, les ténèbres règnent.`,
    aboutDesc3: `C'est une époque de désespoir et de mort, alors que les armées du Betrayer couvrent le pays.`,
    aboutDesc4: `Mais des ruines surgit un salut possible : le dernier ordre guerrier autrefois puissant a été ressuscité par une ancienne déesse connue sous le nom de Sentinelle. Ensemble, vous allez tenter d'apporter une étincelle d'espoir à cette terre désolée.`,
    aboutDesc5: `Devenez un explorateur de donjon intrépide, un mage de combat chasseur de primes ou un puissant guerrier, en moulinant pour obtenir du butin et des équipements rares. Fabriquez des équipements épiques, pliez la magie de la nature à votre volonté et vengez vos frères tombés au combat dans une quête épique de puissance et de vengeance.`,
    contactUsDesc: `Devenez un ambassadeur de ${config.appName} et gagnez des jetons de jeu, de l'or et des gemmes.`,
    contactUs: "Contactez-nous",
    asSeenOnTtl: "Tel que vu sur",
    facTtl1: `Découvrez le monde magnifique d'Ezura.`,
    facTtl2: `Maîtrisez une grande variété d'armes et d'éléments`,
    facTtl3: `Créez des combinaisons d'attaques dévastatrices.`,
    facTtl4: "Fabriquez des engins et des armes uniques et puissants.",
    facTtl5: "Utilisez le jeu de hack n slash pour massacrer vos adversaires.",
    facTtl6: `Affrontez d'autres joueurs dans un JcJ rapide et brutal.`,
    teamBio1:
      "A 36-year veteran of the video game industry who has worked on game titles, such as The Sims, Sims City, Ghost Recon, Rainbow Six, and more), on many levels - from programming to CEO.",
    teamBio2:
      "Worked extensively on the Sony PlayStation and Xbox marketing accounts as well as some of the most iconic game titles including Halo, Call of Duty, Assassin’s Creed and the Tom Clancy franchises.",
    teamBio3:
      "Over 10 years of experience as a gaming executive focused on video games publishing and sales of PC/consoles and mobile Free-To-Play and blockchain-enabled games. Cofounder of Artifex Mundi S.A.",
    teamBio4:
      "Over ten years of experience in community building, marketing, public and client relations, content writing andcreation, and social media management including projects in web3, gamefi, and defi.",
    teamBio5: "A decade of experience in UI/UX design",
    teamBio6:
      "Former Director of Narrative Design for Cyberpunk and The Witcher 3",
    teamBio7:
      "Star Judge and world champion bladesmith on the History Channel’s hit show, Forged in Fire",
    teamBio8:
      "Lead writer for the upcoming megahit: Gord Author and Hollywood Screenplay Writer",
    teamName1: "James Seaman",
    teamTtl1: "CEO",
    teamName2: "Jeremy Brown",
    teamTtl2: "COO",
    teamName3: "Mariusz Szynalik",
    teamTtl3: "Project Director for HitBox Games",
    teamName4: "Yupeng Qin",
    teamTtl4: "Project Director for Asian Dev Team",
    teamName5: "Jakub Szamalek",
    teamTtl5: "Narrative Director",
    teamName6: "John Moyer",
    teamTtl6: "Bass Guitarist of the band Disturbed",
    teamName7: "Vee Lozano",
    teamTtl7: "Business Development Lead",
    teamName8: "Jason Hung",
    teamTtl8: "Advisor",
    teamName9: "Charlie Hu",
    teamTtl9: "Advisor",
    teamName10: "Adam Vigne",
    teamTtl10: "Directeur de la conception narrative",
    teamName11: "Ben Abbott",
    teamTtl11: "Créateur d'armes et consultant",
    teamName12: "Kamran Sadikhov",
    teamTtl12: "UI/UX Designer",
    teamTtl: `L'équipe`,
    partnersTtl: "Partenaires",
    backersTtl: "Soutiens",
    launchPartnTtl: "Partenaires de lancement",
    techPartnTtl: "Partenaires technologiques",
    comPartnTtl: "Partenaires communautaires",
    presTtl: "Comment acheter",
    presStep1: "ETAPE 1",
    presStep2: "ETAPE 2",
    presStep3: "ETAPE 3",
    presStep1Desc: `Tout d'abord, assurez-vous que le portefeuille MetaMask est installé sur votre navigateur, ou utilisez l'un des portefeuilles pour vous connecter à l'un des portefeuilles pris en charge (nous recommandons Trust Wallet).<br>L'achat à partir d'un navigateur de bureau vous donnera une expérience d'achat plus fluide. Pour cela, nous recommandons Metamask. <br>Si vous achetez sur un téléphone mobile, nous vous recommandons d'utiliser Trust Wallet et de vous connecter via le navigateur intégré (il suffit de copier https://swordsofblood.com vers le navigateur de Trust Wallet).`,
    presStep2Desc: `Une fois que vous avez choisi le fournisseur de portefeuille que vous voulez utiliser, cliquez sur Connect Wallet et sélectionnez l'option appropriée. Dans le cas d'une application de portefeuille mobile, vous devrez sélectionner Connecter le portefeuille.`,
    presStep3Desc: `Vous pouvez acheter ${config.tokenSymbol} avec ERC20 ETH, USDT, USDC et DAI. Une fois toutes les étapes de prévente terminées, vous pourrez réclamer votre ${config.tokenSymbol} via cette page. Veuillez attendre notre annonce.`,

    date: {
      Days: "Jours",
      Hours: "heures",
      Minutes: "minutes",
      Seconds: "secondes",
    },
    timerLabel: "La prévente 3 se termine dans :",
    investorTtl: "Investisseurs d'amorçage",
  };
}
